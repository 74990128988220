<template>
    <div>
        <p class="text">{{ $t('faq.emby.step4.setCountry') }}</p>
        <p class="text">{{ $t('faq.emby.step4.inCountryChoose') }}</p>
        <p class="text margin-step-4">{{ $t('faq.emby.step4.systemSupport') }}</p>
        <div class="screenshot">
            <img class="step-image" src="../assets/screen-4.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
.margin-step-4 {
    margin: 32px 0;
}
</style>
